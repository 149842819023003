import * as React from "react";
import Arrow from "../../assets/icons/arrow-right.svg";

export interface UserProfile {
  impact: ValueMapping[];
  type: ValueMapping[];
  keyAreas: ValueMapping[];
}

interface Expertise {
  name: string;
  value: string;
  label: string;
}

export interface ValueMapping {
  code: string;
  name: string;
  value: string;
  score?: number;
}

export interface Recommendation {
  ID: number;
  title: string;
  slug: string;
  description_excerpt: string;
  filters: string[];
  expertise: Expertise[];
  score?: number;
  impacts?: ValueMapping[];
  types?: ValueMapping[];
}

interface Props {
  profile: UserProfile;
  recommendations: Recommendation[];
}

const SERVICE_PROVIDERS_FILTER = "93_1";
const LEVELS = ["Novice", "Beginner", "Proficient", "Expert"];

const containsAll = (arr: string[], target: string[]) =>
  target.every((v) => arr.includes(v));

function filterRecs(
  list: Recommendation[],
  profile: UserProfile
): Recommendation[] {
  list = list.filter((rec) => {
    return containsAll(rec.filters, [
      SERVICE_PROVIDERS_FILTER,
      ...profile.keyAreas.map((e) => e.code),
    ]);
  });
  list.forEach((rec) => {
    rec.score = 0;
    profile.keyAreas.forEach((pk) => {
      const rScore = rec.expertise.find((exp) =>
        exp.name === "User interest and local economy"
          ? pk.code === "95_3"
          : exp.name === pk.name
      )?.value;
      if (rScore) {
        if (Number.parseInt(rScore) === pk.score!) {
          rec.score! += 3;
        } else if (Number.parseInt(rScore) < pk.score!) {
          rec.score! += 1;
        } else {
          rec.score! -= 3;
        }
      }
    });
    const impactScore = rec.filters.filter((e) =>
      profile.impact.map((v) => v.code).includes(e)
    ).length;
    const typeScore = rec.filters.filter((e) =>
      profile.type.map((v) => v.code).includes(e)
    ).length;
    if (impactScore > 0 && typeScore > 0) {
      rec.score! += 3;
    } else if (impactScore === 0 && typeScore === 0) {
      rec.score! -= 1;
    }
  });
  return list.sort((a, b) => b.score! - a.score!).slice(0, 10);
}

const cleanDescription = (description: string) => {
  let cleaned = description?.replace("<p>", "");
  cleaned = cleaned?.replace("</p>", "");
  cleaned = cleaned.replace("[&#8230;]", "...");
  return cleaned;
};

const Recommendations = ({ profile, recommendations }: Props) => {
  recommendations = filterRecs(recommendations, profile);

  return (
    <div>
      <div className="my-8">
        <h1>{recommendations.length} Personalized recommendations:</h1>
      </div>
      {recommendations.length === 0 ? (
        <div
          key="no-result"
          className="rounded-lg col-span-2 border border-solid p-4 bg-white mx-auto my-4 flex flex-col"
        >
          <h2>No Results</h2>
          <p className="my-4">
            We do not have anything that would suit your specific profile at the
            moment but you may anyway find interesting content in our Guide to
            Autonomy accessible through the link below:
          </p>
          <a
            href="https://guide2autonomy.eu"
            target="_blank"
            className="border-2 border-dark ml-0 rounded-full inline-flex items-center mx-auto p-4"
          >
            View all recommendations
            <Arrow className="w-5 h-5"></Arrow>
          </a>
        </div>
      ) : (
        ""
      )}
      {recommendations.map((r) => (
        <div
          key={r.ID}
          className="rounded-lg col-span-2 border border-solid p-4 bg-white mx-auto my-4 flex flex-col"
        >
          {
            <div className="flex flex-row flex-wrap">
              {profile.keyAreas.map((ka) => (
                <span
                  key={ka.code}
                  className="rounded-full border-solid border-[1px] border-orange text-black font-bold p-1.5 text-xs m-2"
                >
                  {
                    r.expertise.find((exp) =>
                      exp.name === "User interest and local economy"
                        ? ka.code === "95_3"
                        : exp.name === ka.name
                    )?.name
                  }{" "}
                  :{" "}
                  {
                    LEVELS[
                      Number.parseInt(
                        r.expertise.find((exp) =>
                          exp.name === "User interest and local economy"
                            ? ka.code === "95_3"
                            : exp.name === ka.name
                        )?.value || "0"
                      )
                    ]
                  }
                </span>
              ))}
            </div>
          }
          <h2>{r.title}</h2>
          {
            <div className="flex flex-row flex-wrap">
              {r.types?.map((type) => (
                <span
                  key={type.code}
                  className={`rounded-full ${
                    profile.type.map((e) => e.code).includes(type.code)
                      ? "bg-orange"
                      : "bg-dark"
                  } text-white p-1.5 text-xs m-2`}
                >
                  Content Type: {type.name}
                </span>
              ))}
              {r.impacts?.map((impact) => (
                <span
                  key={impact.code}
                  className={`rounded-full ${
                    profile.impact.map((e) => e.code).includes(impact.code)
                      ? "bg-orange"
                      : "bg-dark"
                  } text-white p-1.5 text-xs m-2`}
                >
                  Impact: {impact.name}
                </span>
              ))}
            </div>
          }
          <p className="my-4">{cleanDescription(r.description_excerpt)}</p>
          <a
            href={"https://guide2autonomy.eu/?rec=" + r.slug}
            target="_blank"
            className="border-2 border-dark ml-0 rounded-full inline-flex items-center mx-auto p-4"
          >
            Read more
            <Arrow className="w-5 h-5"></Arrow>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Recommendations;
