import { navigate } from "gatsby";
import * as React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Layout from "../components/layout";
import Recommendations, {
  Recommendation,
  UserProfile,
  ValueMapping,
} from "../components/recommendations/recommendations";

const LEVELS = ["Novice", "Beginner", "Proficient", "Expert"];

const IMPACTS = [
  { code: "94_0", name: "Individual level", value: "individual" },
  { code: "94_1", name: "Vulnerable users", value: "users" },
  {
    code: "94_2",
    name: "Macro-level (societal, economic, environmental)",
    value: "macro",
  },
];

const TYPE = [
  { code: "96_0", name: "Software", value: "software" },
  { code: "96_1", name: "Research method", value: "research" },
  { code: "96_2", name: "Business model", value: "business" },
  { code: "96_3", name: "Other", value: "others" },
];

const KEY_TOPICS: ValueMapping[] = [
  { code: "95_0", name: "Public policies", value: "A_0_T_0_C_1_PC" },
  {
    code: "95_1",
    name: "Infrastructure and technologies",
    value: "A_0_T_0_C_0_PC",
  },
  { code: "95_2", name: "ICT developments", value: "A_0_T_0_C_3_PC" },
  {
    code: "95_3",
    name: "Management and local economy",
    value: "A_0_T_0_C_2_PC",
  },
];

const getResult = (uid: string) => {
  return fetch(
    `${process.env.CROSSSKILL_BASE_URL}/taoSkillcard/DeliveryResults/getResultWithUID?uid=${uid}`
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
};

const deleteResult = (uid: string) => {
  return fetch(
    `${process.env.CROSSSKILL_BASE_URL}/taoSkillcard/DeliveryResults/deleteResultWithUID?uid=${uid}`
  ).catch((error) => console.log(error));
};

function getRecommendations(): Promise<Recommendation[]> {
  return fetch(
    `https://guide2autonomy.eu/api/pascal/v1/recommendations/615ad7d33b319ea6f383c9f0317f0f215`
  )
    .then((response) => {
      return response.json().then((recs) => {
        recs.forEach((r: Recommendation) => {
          r.impacts = IMPACTS.filter((e) => r.filters.includes(e.code));
          r.types = TYPE.filter((e) => r.filters.includes(e.code));
        });
        return recs;
      });
    })
    .catch((error) => console.log(error));
}

const answeredQuestions = (items: any, key: string) => {
  return !!Object.keys(items).find((k) => {
    return k.includes(key.replace("_PC", ""));
  });
};

// markup
const ResultsPage = () => {
  const [uid, setUid] = useQueryParam("uid", StringParam);
  const [recommendations, setRecommendataions] = React.useState<
    Recommendation[]
  >([]);
  const [userProfile, setUserProfile] = React.useState<UserProfile>({
    impact: [],
    type: [],
    keyAreas: [],
  });
  React.useEffect(() => {
    if (!!uid) {
      Promise.all([getResult(uid), getRecommendations()]).then((values) => {
        setRecommendataions(values[1]);
        const resultData = values[0];
        if (resultData && resultData.length > 0) {
          const results = resultData.slice(-1)[0];
          if (results) {
            const userProfile: UserProfile = {
              impact: JSON.parse(
                results?.items[
                  "PREQUESTIONS_1.0"
                ].responseVariables.RESPONSE.replace(/'/g, '"').replaceAll(
                  ";",
                  ","
                )
              ).map((v: any) => IMPACTS.find((e) => e.value === v)),
              type: JSON.parse(
                results?.items[
                  "PREQUESTIONS_2.0"
                ].responseVariables.RESPONSE.replace(/'/g, '"').replaceAll(
                  ";",
                  ","
                )
              ).map((v: any) => TYPE.find((e) => e.value === v)),
              keyAreas: KEY_TOPICS.filter((k) => {
                return answeredQuestions(results?.items, k.value);
              }).map((k) => {
                k.score = results?.variables[k.value] / 25 - 1;
                return k;
              }),
            };
            setUserProfile(userProfile);
            if (process.env.PRODUCTION) {
              return deleteResult(uid);
            }
          }
        } else {
          navigate("/");
        }
      });
    }
  }, ["uid"]);

  console.log("Profile:", userProfile);
  return (
    <Layout pageTitle="Your recommendations">
      <div className="mx-auto flex flex-col max-w-5xl overflow-scroll">
        <div className="my-8 px-8 xl:px-0">
          <h1>Your profile</h1>
          <p>Here are the results from your self assessment:</p>
          <div className="my-8 border border-dotted"></div>
          <h2>Declared Interests:</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="rounded-lg border border-solid p-4 bg-white">
              <h3>Expected Impact:</h3>
              <ul className="list-inside list-disc">
                {userProfile.impact.map((e) => (
                  <li key={e.code}>{e.name}</li>
                ))}
              </ul>
            </div>
            <div className="rounded-lg border border-solid p-4 bg-white">
              <h3>Type of Tools:</h3>
              <ul className="list-inside list-disc">
                {userProfile.type.map((e) => (
                  <li key={e.code}>{e.name}</li>
                ))}
              </ul>
            </div>
            <div className="rounded-lg col-span-2 border border-solid p-4 bg-white">
              <h3>Key Topics:</h3>
              <ul className="list-inside list-disc">
                {userProfile.keyAreas.map((ka) => (
                  <li key={ka.code}>{ka.name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="my-8 border border-dotted"></div>
          <h2>Estimated proficiency level:</h2>
          <div className="grid grid-cols-2 gap-4">
            {userProfile.keyAreas.map((ka) => (
              <div
                key={ka.code}
                className="flex flex-col rounded-lg border border-solid p-4 bg-white"
              >
                <p className="mx-auto text-lg">{ka.name}</p>
                <h2 className="mx-auto p-2">{LEVELS[ka.score!]}</h2>
              </div>
            ))}
          </div>
          {
            <Recommendations
              profile={userProfile}
              recommendations={recommendations}
            ></Recommendations>
          }
        </div>
      </div>
    </Layout>
  );
};

export default ResultsPage;
